<template>
  <UiPopup
    v-model="modelValue"
    title="Filters"
    primary-button-text="Apply"
    secondary-button-text="Reset"
    size="extraBig"
    :secondary-button-closes="false"
    @cancel="reset"
    @confirm="applyFilters"
  >
    <template #title>
      <h3>Filters</h3>
    </template>
    <template #default>
      <div class="grid grid-cols-3 gap-12 overflow-hidden">
        <div class="flex flex-col gap-8">
          <UiInputSelect
            v-model="localFilters.locations"
            :items="filtersMap.locations.items!"
            name="districts"
            :loading="filtersMap.locations.loading"
            multiple
            compact
            label="City districts"
            placeholder="City districts"
            persistent-placeholder
          />
          <UiInputSelect
            v-model="localFilters.property_type"
            :items="filtersMap.propertyTypes.items!"
            :loading="filtersMap.propertyTypes.loading"
            name="propertyTypes"
            multiple
            compact
            label="Property types"
            placeholder="Property types"
            persistent-placeholder
          />
          <div class="flex flex-row items-end gap-4">
            <UiInputTextField
              id="area-from"
              v-model="localFilters.area_min"
              name="area-from"
              label="Property area"
              placeholder="From"
              suffix="m2"
              compact
              type="number"
              :error="useGetFieldErrors(v$, ['area_min'])"
            />
            <span class="mb-3">-</span>
            <UiInputTextField
              id="area-to"
              v-model="localFilters.area_max"
              name="area-to"
              compact
              placeholder="To"
              suffix="m2"
              type="number"
              :error="useGetFieldErrors(v$, ['area_max'])"
            />
          </div>
          <UiInputSelect
            v-model="localFilters.amenities"
            :items="filtersMap.amenities.items!"
            :loading="filtersMap.amenities.loading"
            multiple
            compact
            label="Amenities"
            name="Amenities"
            placeholder="Amenities"
            persistent-placeholder
          />
        </div>
        <div class="flex flex-col gap-8">
          <UiInputOptions
            id="stage"
            v-model="localFilters.building_status"
            name="stage"
            :items="filtersMap.buildingStatuses.items!"
            :loading="filtersMap.buildingStatuses.loading"
            label="Construction stages"
          />
          <UiInputOptions
            id="bedrooms"
            v-model="localFilters.bedrooms"
            name="bedrooms"
            :items="filtersMap.bedrooms.items!"
            :loading="filtersMap.bedrooms.loading"
            label="Bedrooms"
            multiple
          />
          <div class="flex flex-row items-center gap-4">
            <UiInputTextField
              id="budget"
              v-model="localFilters.price_max"
              name="budget"
              label="Budget"
              placeholder="Number"
              type="number"
              prefix="AED"
              compact
              :error="useGetFieldErrors(v$, ['price_max'])"
            />
            <UiInputTextField
              id="down-payment"
              v-model="localFilters.down_payment"
              name="down-payment"
              label="Down payment"
              placeholder="Number"
              type="number"
              prefix="AED"
              compact
              :error="useGetFieldErrors(v$, ['down_payment'])"
            />
          </div>
          <UiInputSelect
            v-model="localFilters.sale_status"
            :items="filtersMap.salesStatuses.items!"
            :loading="filtersMap.salesStatuses.loading"
            multiple
            compact
            label="Sales status"
            name="sales-status"
            placeholder="Any"
            persistent-placeholder
          />
        </div>
        <div class="flex flex-col gap-8">
          <div class="flex flex-col gap-2">
            <div class="flex flex-row items-center gap-2">
              <UiInputTextField
                id="floor-from"
                name="floor-from"
                placeholder="Not lower ex.3"
                label="Floor"
                disabled
                model-value=""
                compact
              />
              <UiInputTextField
                id="floor-to"
                name="floor-to"
                placeholder="Not higher ex.7"
                label="Floor"
                disabled
                model-value=""
                compact
              />
            </div>
            <UiInputCheckbox v-model="localFilters.floor_plan" name="floorPlanAvailable" label="Floor plan available" />
          </div>
          <UiInputCheckbox
            v-model="localFilters.post_handover_payment_plan"
            name="postHandoverPaymentPlan"
            label="Post-handover payment plan"
          />
          <UiInputCheckbox v-model="localFilters.roi_percent" name="guaranteedROI" label="Guaranteed ROI" />
        </div>
      </div>
    </template>
  </UiPopup>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { useVuelidate } from '@vuelidate/core'
import { maxValue, helpers } from '@vuelidate/validators'
import type { DynamicFilter, PropertyTypes } from '@/types'
import { DICTIONARIES } from '@/constants'

const emits = defineEmits(['update:modelValue', 'cancel', 'input', 'reset'])

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  filters: PropertyTypes.Filters
}

const props = defineProps<Props>()

const localFilters = ref<PropertyTypes.Filters>({ ...cloneDeep(props.filters) })

const rules = computed(() => ({
  area_min: { maxValue: helpers.withMessage('Property min area is too high', maxValue(999999999)) },
  area_max: { maxValue: helpers.withMessage('Property max area is too high', maxValue(999999999)) },
  down_payment: { maxValue: helpers.withMessage('Down payment is too high', maxValue(999999999)) },
  price_max: { maxValue: helpers.withMessage('Budget is too high', maxValue(999999999)) },
}))

const v$ = useVuelidate(rules, localFilters)

const filtersMap: Record<string, DynamicFilter> = reactive({
  locations: {
    name: 'locations',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_LOCATIONS)),
  },
  propertyTypes: {
    name: 'property_type',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_TYPES)),
  },
  amenities: {
    name: 'amenities',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_AMENITIES)),
  },
  buildingStatuses: {
    name: 'building_status',
    items: [
      {
        value: null,
        text: 'Any',
      },
    ],
    init: async () =>
      useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_BUILDING_STATUSES)),
  },
  bedrooms: {
    name: 'bedrooms',
    items: [
      {
        value: null,
        text: 'Any',
      },
      {
        value: 0,
        text: 'Studio',
      },
      {
        value: 1,
        text: '1',
      },
      {
        value: 2,
        text: '2',
      },
      {
        value: 3,
        text: '3',
      },
      {
        value: 4,
        text: '4+',
      },
    ],
  },
  salesStatuses: {
    name: 'sale_status',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)),
  },
})

const getFiltersValues = async () => {
  await Promise.all(
    Object.entries(filtersMap).map(async ([_, filter]) => {
      if (filter.init) {
        filter.loading = true
        filter.items = filter.items?.concat(await filter.init())
        filter.loading = false
      }
    })
  )
}

onNuxtReady(async () => {
  await getFiltersValues()
})

const reset = () => {
  localFilters.value = {
    query: '',
    property_type_ids: [],
    sale_status: [],
    bedrooms: [],
    budget: [],
    order_by: '-updated_at',
    order_way: 'asc',
  }
  emits('reset')
}

const applyFilters = async () => {
  if (!(await v$.value.$validate())) return
  emits('input', localFilters.value)
  modelValue.value = false
}
</script>

<style scoped></style>
